import React, { useEffect, useState } from 'react';
import CallUsLogo from '../../assets/images/Icons/call-removebg-preview.png'; // Your call button logo

import './CallButton.css'; // Import the CSS file for styling

function CallButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.5) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className={`call-button-container ${isVisible ? 'show' : ''}`}>
          <a href="tel:+918368163883" className="call-button">
            <img src={CallUsLogo} alt="Call Us" className="call-logo" />
          </a>
        </div>
      )}
    </>
  );
}

export default CallButton;
